import { useRef } from "react";
import PropTypes from "prop-types";
import { normalizedData } from "../utils";
// import Seo from "@components/seo";
import HeroArea from "@containers/hero/layout-05";
import ITSolutionArea from "@containers/it-solution/layout-01";
import ITServicesArea from "@containers/it-service/layout-02";
import ItSolutionArea from "@containers/it-solution/layout-05";
import FunfactArea from "@containers/funfact/layout-03";
import ITServiceArea from "@containers/it-service/layout-04";
import ContactInfoArea from "@containers/contact/layout-07";
import ContactArea from "@containers/contact/layout-05";
import TestimonialArea from "@containers/testimonial/layout-02";
import Clients from "@containers/testimonial/layout-04";
import WhatsNewArea from "@containers/whats-new/layout-01";
import CtaArea from "@containers/cta/layout-04";
import PartnerArea from "@containers/partner/layout-01";
import CaseStudyArea from "@containers/case-study/layout-01";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import headerData from '../data/headerData.json'
import homepageData from '../data/homepageData.json'
import Layout from "../layouts";
import SectionOne from "@containers/elements/accordion/section-01";
import data from '@data/data.json'
import Button from "@components/ui/button";

const Home = ({ location }) => {
    

    const homeRef = useRef();
    const aboutusRef = useRef();
    const servicesRef = useRef();
    const ourvaluesRef = useRef();
    const contactusRef = useRef();
    const clientsRef = useRef();
console.log('branch 2')
    const scrollToServices = (path) => {
        console.log({path})
        switch (path) {
            case "/":
                window.scrollTo({ top: 0, behavior: "smooth" });
            break;
            case "/#about-us":
                aboutusRef.current.scrollIntoView({ behavior: "smooth" });
            break;
            case "/#services":
                servicesRef.current.scrollIntoView({ behavior: "smooth" });
            break;
            case "/#values":
                ourvaluesRef.current.scrollIntoView({ behavior: "smooth" });
            break;

            case "/#contact-us":
                contactusRef.current.scrollIntoView({ behavior: "smooth" });
            break;

            case "/#clients":
                clientsRef.current.scrollIntoView({behavior: "smooth"})
            break;
            
            default:
                window.scrollTo({ top: 0, behavior: "smooth" });
                break;
        }
        // servicesRef.current.scrollIntoView({ behavior: "smooth" });
      };
console.log('branch 3')
console.log('branch 4')
    const content = homepageData;
    const globalContent = headerData;
console.log('branch 6')
console.log('branch 7')
console.log('branch 9')
console.log('branch 8')
console.log({servicesRef, content, globalContent})
    return (
        <Layout ref={homeRef} location={location}>
            {/* <Button className={'btn btn-default'} onClick={scrollToServices}>Services</Button> */}
            {/* <Seo title="Resolutions" /> */}
            <Header
                transparent
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    contact: '',
                }}
                scrollToServices={scrollToServices}
            />
            <main className="site-wrapper-reveal">
                 <HeroArea data={content["hero-section"]} />
                 <div ref={aboutusRef}>
                {/* <PartnerArea data={content["partner-section"]} /> */}
                <TestimonialArea data={content["aboutus-section"]} />
                </div>
                {/* <ItSolutionArea
                    data={{
                        ...content["feature-section"],
                        items: data.features.nodes,
                    }}
                /> */}
                {/* <FunfactArea data={content["funfact-section"]} bg="gray.100" /> */}
                <div ref={servicesRef}>
                <CaseStudyArea
                    data={{
                        ...content["case-study-section"],
                        items: data.allCaseStudy.nodes,
                    }}
                />
                </div>
                {/* <ITServiceArea
                    data={{
                        ...content["service-section"],
                        items: data.services.nodes,
                    }}
                /> */}
                {/* <div ref={ourvaluesRef}> */}
                    {/* <ContactArea data={content["contact-section"]} /> */}
                    {/* <ITSolutionArea
                    data={{
                        ...content["feature-section"],
                        items: data.allItSolution.nodes,
                    }}
                /> */}
                {/* </div> */}
                {/* <TestimonialArea data={content["testimonial-section"]} /> */}
                {/* <WhatsNewArea data={content["whats-new-section"]} /> */}
                <div ref={contactusRef}>
                <ContactArea data={content["contact-section"]} />
                {/* <ContactInfoArea data={content["contact-info-section"]} /> */}
                {/* <CtaArea data={content["cta-section"]} /> */}
                </div>
                <div ref={clientsRef}>

                <Clients data={content["clients-section"]} />
                </div>

                {/* <PartnerArea data={content["partner-section"]} />  */}
            </main>
            <Footer data={data.site.siteMetadata} />
        </Layout>
    );
};


Home.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                socials: PropTypes.arrayOf(PropTypes.shape({})),
                contact: PropTypes.shape({}),
            }),
        }),
        features: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        services: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Home;
