import React from "react";
import PropTypes from "prop-types";
import Image from "@ui/image";
import { ImageType } from "@utils/types";
import {
    TestimonialWrap,
    TestimonialInfo,
    TestimonialMedia,
    TestimonialAuthor,
    TestimonialRating,
    AuthorInfo,
    AuthorName,
    AuthorRole,
    Review,
    ListDetails,
    IconWrap,
    TWrapList,
} from "./style";
import List, { ListItem } from "@components/ui/list";
import Text from "@components/ui/text";

const Testimonial = ({
    authorName,
    authroRole,
    authorImg,
    rating,
    review,
    ...rest
}) => {
    const ratingItems = [];
    const disableRating = [];
    // for (let i = 1; i <= rating; i++) {
    //     ratingItems.push(
    //         <span key={`rating-${i}`}>
    //             <i className="rating fa fa-star"></i>
    //         </span>
    //     );
    // }

    // if (rating < 5) {
    //     for (let i = 1; i <= 5 - rating; i++) {
    //         disableRating.push(
    //             <span key={`disable-rating-${i}`}>
    //                 <i className="rating disable fa fa-star"></i>
    //             </span>
    //         );
    //     }
    // }

    return (
        <TestimonialWrap {...rest}>
            {/* <TestimonialInfo>
                {authorImg?.src && (
                    <TestimonialMedia>
                        <Image
                            src={authorImg.src}
                            alt={authorImg?.alt || authorName}
                        />
                    </TestimonialMedia>
                )}
                <TestimonialAuthor>
                    {rating && (
                        <TestimonialRating>
                            {ratingItems}
                            {disableRating}
                        </TestimonialRating>
                    )}
                    <AuthorInfo>
                        {authorName && <AuthorName>{authorName}</AuthorName>}
                        {authroRole && <AuthorRole>{authroRole}</AuthorRole>}
                    </AuthorInfo>
                </TestimonialAuthor>
            </TestimonialInfo> */}
            {review && <Review><b>Incredible Solutions </b> {review}</Review>}
            <Text fontSize="18px">
            We Proudly sever with,
            <TWrapList>
                <ListDetails>
                <IconWrap className="fa fa-arrow-right fa-xs"></IconWrap> Tech challenges? We've got solutions!
                </ListDetails>
                <ListDetails>
                <IconWrap className="fa fa-arrow-right fa-xs"></IconWrap> Empowering education with stealth tech integration.
                </ListDetails>
                <ListDetails>
                <IconWrap className="fa fa-arrow-right fa-xs"></IconWrap> Empower students with tech training!
                </ListDetails>
                </TWrapList>
                </Text>
        </TestimonialWrap>
    );
};

Testimonial.propTypes = {
    authorImg: PropTypes.shape(ImageType),
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    authorName: PropTypes.string,
    authroRole: PropTypes.string,
    review: PropTypes.string,
};

export default Testimonial;
