import React from "react";
import PropTypes from "prop-types";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/header/layout-01-new";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import PrivacyPolicyArea from "@containers/privacy-policy/layout-04";
import ITSolutionArea from "@containers/it-solution/layout-06";
import CtaArea from "@containers/cta/layout-05";
import CtaAreaBottom from "@containers/cta/layout-04";
import SolutionArea from "@containers/solution/layout-01";
import FunFactArea from "@containers/funfact/layout-02";
import TestimonialArea from "@containers/testimonial/layout-02";
import PartnerArea from "@containers/partner/layout-01";
import ContactArea from "@containers/contact/layout-06";
import data from '@data/data.json'
import headerData from '@data/headerData.json'
import privacypolicyData from '@data/privacypolicyData.json'

const PrivacyPolicy = ({ location }) => {
    const content = privacypolicyData;
    const globalContent = headerData;

    return (
        <Layout location={{}}>
            {/* <Seo title="About Us" /> */}
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                }}
            />
            <main className="site-wrapper-reveal">
                {/* <PageHeader data={content["page-header-section"]} /> */}
                <PrivacyPolicyArea data={content["privacy-policy"]} />
                {/* <ITSolutionArea
                    data={{
                        ...content["feature-section"],
                        items: data.allItSolution.nodes,
                    }}
                /> */}
                {/* <CtaArea data={content["cta-section"]} /> */}
                {/* <SolutionArea data={content["solution-section"]} /> */}
                 {/* <FunFactArea data={content["funfact-section"]} /> */}
               {/* <TestimonialArea data={content["testimonial-section"]} />
                <PartnerArea data={content["partner-section"]} /> */}
                <CtaAreaBottom data={content["cta-section-bottom"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

PrivacyPolicy.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default PrivacyPolicy;
