import styled, { device } from "@styled";
import themeGet from "@styled-system/theme-get";
import { css } from "styled-components";

export const CaseStudyWrapper = styled.div`
    position: relative;
    isolation: isolate;
    padding-block: 60px;
    padding-inline: 15px;
    background-position: bottom left;
    background-size: auto;
    ${device.medium} {
        padding-block: 80px;
    }
    ${device.large} {
        padding-block: 100px;
    }
`;
export const HeadingWrap = styled.h2`
    line-height: 1.25;
    color: ${themeGet("colors.primary")};
    ${(props) =>
        props.$layout !== 2 &&
        css`
            font-weight: 500;
            margin-block-end: 12px;
        `}
    ${(props) =>
        props.$layout === 2 &&
        css`
            font-weight: 600;
            margin-block-end: 2px;
            font-size: 18px;
        `}
`;

export const StyledBG = styled.div`
    position: absolute;
    inset: auto 0 0 0;
    z-index: -1;
`;
