import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import ContactForm from "@components/forms/contact-form";
import MarkHeading from "@components/mark-heading";
import { HeadingType, TextType } from "@utils/types";
import { StyledSection, LeftBox, StyledHeading, StyledDesc, TextColor } from "./style";
import Text from "@components/ui/text";
import Anchor from "@components/ui/anchor";
import Heading from "@components/ui/heading";
import SectionTitle from "@components/ui/section-title";

const ContactArea = ({ data }) => {
    return (
        <StyledSection>
             {data?.section_title && (
                    <SectionTitle mb={5}
                        subtitle={data.section_title?.subtitle}
                        title={data.section_title?.title}
                    />
                )}
            <Container>
           
                <Row alignItems="center">
                 
                    <Col lg={6}>
                        <LeftBox>
                            {/* {data?.headings?.[0] && (
                                <StyledHeading
                                    as={data.headings[0].level}
                                    dangerouslySetInnerHTML={{
                                        __html: data.headings[0].content,
                                    }}
                                />
                            )} */}
                             {data?.items?.map((info) => (
                        <Col lg={4} key={info.id} mb="30px">
                            {info.title && (
                                <Heading as="h5" mb="20px">
                                    {info.title}
                                </Heading>
                            )}
                            {info?.texts && (
                                <>
                                    {info.texts?.[0] && (
                                         <>
                                         <Heading as="h5" mt="20px">
                                            Address: 
                                        </Heading>
                                        <TextColor>
                                        <Text mb="7px">
                                            {info.texts[0]?.content}
                                        </Text>
                                        </TextColor>
                                        </>
                                    )}
                                     {info.texts?.[1] && (
                                        <>
                                         <Heading as="h5" mt="20px">
                                            Email: 
                                        </Heading>
                                        <TextColor>
                                        <Anchor
                                            path={`mailto:${info.texts[1]?.content}`}
                                        >
                                            {info.texts[1]?.content}
                                        </Anchor>
                                        </TextColor>
                                        </>
                                    )}
                                    
                                   
                                    {info.texts?.[2] && (
                                        <>
                                         <Heading as="h5" mt="20px">
                                            {/* {info.text[2].title} */}
                                            Call:
                                        </Heading>

                                        <TextColor>
                                            <Text
                                                mb="7px"
                                                // fontWeight={800}
                                            >
                                                {info.texts[2]?.content}
                                            </Text>
                                        </TextColor>
                                        </>
                                    )}
                                </>
                            )}
                        </Col>
                    ))}
                            {/* {data?.texts?.[0] && (
                                <StyledDesc>{data.texts[0].content}</StyledDesc>
                            )} */}
                        </LeftBox>
                    </Col>
                    <Col lg={6}>
                        <ContactForm />
                    </Col>
                </Row>
            </Container>
        </StyledSection>
    );
};

ContactArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    }),
};

export default ContactArea;
