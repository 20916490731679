import React from "react";
import PropTypes from "prop-types";
import { normalizedData } from "@utils";
import Layout from "@layout";
import Header from "@layout/header/layout-01-new";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ITServiceArea from "@containers/it-service/layout-02";
import ContactArea from "@containers/contact/layout-06";
import headerData from '@data/headerData.json'
import itServiceData from '@data/itServiceData.json'
import data from '@data/data.json'

const ItServicesPage = ({ pageContext, location }) => {
    const content = itServiceData;
    const globalContent = headerData;
    console.log({data})
    return (
        <Layout location={{}}>
            {/* <Seo title="IT Services" /> */}
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={{}}
                    title="IT Services"
                />
                <ITServiceArea
                    data={{
                        ...content["service-section"],
                        items: data?.allItService?.nodes,
                    }}
                    bg="white"
                />
                <ContactArea
                    data={{
                        ...content["contact-section"],
                        contact: data.site.siteMetadata.contact,
                    }}
                />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};


ItServicesPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItService: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItServicesPage;
