import React from "react";
import PropTypes from "prop-types";
import { LogoWrapper } from "./style";
import { Link } from "react-router-dom";
import logo from '../../assets/images/logo/new-logo.png'

const Logo = ({ className, variant, scrollToServices, ...restProps }) => {
    return (
        <LogoWrapper className={className} $variant={variant} {...restProps}>
            <Link onClick={scrollToServices} to="/">
                <img
                    src={logo}
                    className="img-fluid static-img light-logo"
                    alt="logo"
                    width={160}
                    height={48}
                />
                <img
                    src={logo}
                    className="img-fluid static-img dark-logo"
                    alt="logo"
                    width={160}
                    height={48}
                />
            </Link>
        </LogoWrapper>
    );
};

Logo.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(["white", "dark"]),
};

Logo.defaultProps = {
    justifyContent: "flex-start",
    variant: "dark",
};

export default Logo;
