import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
  } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Contactus from "./pages/Contactus";
import ItServicesPage from "./pages/Services";
import CareersPage from "./pages/Careers";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/#about-us",
      element: <About />,
    },
    {
        path: "/#contact-us",
        element: <Contactus />,
    },
    {
      path: "/#services",
      element: <ItServicesPage />,
    },
    {
      path: "/#careers",
      element: <CareersPage />
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />
    }
  ]);

  export default router